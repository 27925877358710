/* src/components/OrientationOverlay.scss */

.orientation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  .message {
    color: #fff;
    font-size: 1.5rem;
    padding: 20px;

    img {
      width: 80px;
      margin-bottom: 20px;
    }
  }
}