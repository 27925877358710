@import "../../vars.module.scss";




button {
  background-color: $background-color_3;
  color: $color_1;
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $background-color_4;
  }
}


.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}