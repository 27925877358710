@import "../../../../vars.module.scss";
.how-it-works {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  text-align: left;

  div {
    width: 30%;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #1D334A;
  }
  >div *{
    margin: 10px 0px;
    text-align: center;
  }
  >div:first-child {
    background-color: #614244;
    color: white;
  }
  >div:last-child {
    background-color: #1A1426;
    color: white;
  }
  img {
    width: 80%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 500px) {
  .how-it-works{
    flex-direction: column;
    align-items: center;
    margin: 10px 0px;
    div {
      text-align: left;
      width: 100%;
      margin: 10px 0px;
    }
  }
}