
@import "../../../../vars.module.scss";
@import 'node_modules/react-modal-video/scss/modal-video.scss';
.question {
  width: 100%;
  min-height: 70vh;
  position: relative;
  h2 {
    font-family: $font-family_3;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  button.videoButton{
    margin-top: 20px;
    background-color: transparent;
    font-size: 20px;
    :not(span) {
      color:#e65c00;
      margin-right: 5px;
    }
    span {
      color: #fff;
      font-family:$font-family_3;
      font-style: italic;
    }
  }
  .answers {
    margin: 50px auto;
    z-index: 5;
    display: flex;
    input {
      display: none;
      &:checked+span {
        border: 2px solid #e65c00;
      }
    }
    label{ margin:10px 0px;}
    span {
      font-family: $font-family_3;
      font-optical-sizing: auto;
      font-style: normal;
      width: 150px;
      height: 150px;
      border-radius: 10px;
      margin: 10px;
      margin-top:15px;
      padding: 10px;
      position: relative;
      border: 0px solid transparent;
      transition: all .3s ease;
      box-shadow: rgba(0, 0, 0, 0.12) 0px -12px 30px;
      &:hover {
        scale: 1.2;
        z-index: 2;
      }
    }
    textarea {
      font-family: $font-family_2;
      font-optical-sizing: auto;
      font-style: normal;
      outline: none;
      background-color: $background-color_1;
      border: 2px solid #333;
      color: $color_1;
      border-radius: 4px;
      height: 200px;
      max-height: 200px;
      min-height: 80px;
      width: 100%;
      max-width: 500px;
      min-width: 300px;
      padding: 20px;
      &:focus {
        border: 2px solid #e65c00;
        background-color: $background-color_2;
      }
    }
  }
  .category {
    font-size: 400%;
    margin: 10px auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
}
.question[data-type="yes/no"] {
  .answers {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    span {
      font-size: 200%;
    }
  }
}
.question[data-type="multiple-choice"] {
  .answers {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }
}
.navigation{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding:0px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
@media screen and (max-width: 500px) {
 h2{font-size: 1rem!important;}
 .category {
   font-size: 200%!important;
 }
 h2 button{
    font-size: .8rem !important;
    padding: 5px !important;
  }
  .answers {
    margin: 10px auto;
  }
    .question[data-type="open-ended"] {
      h2 {
          font-size: .8rem!important;
        }
                .answers {
                  margin: 10px auto;
                }
    }
    .question[data-type="multiple-choice"] {
      div > div{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
      }
            div>div span{
              font-size: .8rem;}
    }
}