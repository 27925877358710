@import './../../vars.module.scss';

.container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  .results{
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 70%;
    min-width: 300px;
    text-align: left;
    margin:15px auto;
    padding:15px;
    h1{
      font-family: $font-family_3;
      font-optical-sizing: auto;
      font-style: normal;
      font-weight: 100;
      font-size: 2rem;
      margin-bottom: 10px;
      text-align: center;
    }
    p+h2, ul+h2, ol+h2{
      margin-top: 25px;
    }
    h2{
      font-family: $font-family_3;
      font-optical-sizing: auto;
      font-style: normal;
      font-weight: 100;
      font-size: 1.5rem;
      margin-bottom: 10px;
    }
    h3{
      font-family: $font-family_3;
      font-optical-sizing: auto;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      margin-bottom: 10px;
      text-align: center;
    }
    h3+p{
      text-align: center;
      padding:15px;
      margin: 15px;
      border: 1px solid #fff;
    }
    ul,ol{
      padding: 15px;
      margin:15px;
      li{ line-height: 30px;}
    }
  }