@import "../../../../vars.module.scss";
.form-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0px;
  form {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  input {
    padding: 15px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    margin:0 10px;
    width: 200px;
    background-color: $background-color_2;
    color: $color_1;
  }
  .error {
    color: red;
    margin-top: 10px;
  }
}
/* HTML: <div class="loader"></div> */
.loader {
  display: inline-block;
  width: 20px!important;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid rgb(243, 243, 243);
  border-right-color: rgb(151, 0, 93);
  animation: l2 1s infinite linear;
}
.loader.hide{
  display: none;
}

@keyframes l2 {
  to {
    transform: rotate(1turn)
  }
}
@media screen and (max-width: 500px) {
  .form-group {
    flex-direction: column;
    align-items: center;
    input {
      margin: 10px 0px;
      width: 100%;
    }
        form {
          flex-direction: column;
          align-items: center;
        }
    button { margin-top:10px;}
  }
}