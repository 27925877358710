@import "../../vars.module.scss";
/*--------------QUESTIONS------------*/
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}
.active-slide {
  font-family: $font-family_3;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 100;
}
.questions {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
}
video {
  /* override other styles to make responsive */
  width: 100% !important;
  height: auto !important;
  max-width: 500px;
}