/* src/components/ShotRecordingReminder.module.scss */

.popover {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 20px;
    right: 20px;
    background-color: #323232;
    color: #fff;
    padding: 16px;
    border-radius: 8px;
    z-index: 1000;
    max-width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  
    &.animate__animated {
      --animate-duration: 1s; /* Animation duration */
    }
  }
  
  .popover p {
    margin: 0 0 8px 0;
  }
  
  .popover button {
    background-color: #ff6f61;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popover button:hover {
    background-color: #ff5a4d;
  }
  