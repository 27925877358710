@import "../../../../vars.module.scss";
.features-section {
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin: 50px 0px;

  div {
    width: 45%;
  }
}

.section-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: left;
}

@media screen and (max-width: 500px) {
  .features-section {
    flex-direction: column;
    align-items: center;
    margin: 10px 0px;
    h2{ text-align: center;}
    div {
      text-align: left;
      width: 100%;
      margin: 20px 0px;
    }
  }
}