$color_1: #fff;
$font-family_1: 'Arial', sans-serif;
$font-family_2: "Work Sans", sans-serif;
$font-family_3: "Playfair Display", serif;
$background-color_1: #0A0A0A;
$background-color_2: #333;
$background-color_3: #ff6600;
$background-color_4: #e65c00;

@import "https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-family_1;
  background-color: $background-color_1;
  color: $color_1;
  line-height: 1.6;
}




button {
  background-color: $background-color_3;
  color: $color_1;
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
  &:hover {
    background-color: $background-color_4;
  }
}

.section-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: left;
}





