@import "../../../../vars.module.scss";
header {
  margin-top: 50px;
  display: flex;
  flex-direction: column;

  img {
    width: 80px;
    margin-bottom: 20px;
    margin: 0 auto;
  }

  h1 {
    font-family: $font-family_2;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 10px;
  }

  p {
    font-family: $font-family_3;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 500px) {
header{margin-top:10px;}
  h1{
    font-size: 1.5rem!important;
    line-height: 1.2;
    margin-bottom: 5px;
  }
  p{
    font-size: .75rem!important;}
}