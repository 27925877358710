/* src/components/modal.module.scss */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Ensure it overlays other content */
}

.modalContent {
  position: relative;
  background-color: #fff;
  padding: 0;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 12px;
  background: none;
  border: none;
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  z-index: 1001;
}